import React from "react";
import Full_Search from "../Components/Full_Search";

function Compare() {
  return (
    <div className="compare-container">
      <iframe className="compare" src="/"></iframe>
      <iframe className="compare" src="/"></iframe>
    </div>
  );
}

export default Compare;
